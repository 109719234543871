import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, PrimaryClaim, EndpointDisplay, PageSubSection, PatientProfile, ComparisonTable, ReferencesBlock } from '../../components';
import ENDO from '../../components/StudyDesign/__study-design/endo';
import { jobCodes } from '../../util/data/jobCodes';
import { StaticImage } from 'gatsby-plugin-image';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-158 - Clinical Trial Results & Study Design | HCP`,
    keywords: `keynote 158, keynote-158`,
    description: `Health care professionals may find clinical trial results for advanced MSI-H/dMMR endometrial carcinoma in KEYNOTE-158. Explore more.`,
    schemaJsonLD: [
        '{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-endometrial-carcinoma/","@type":"ApprovedIndication","name":"KEYTRUDA® ","alternateName":"pembrolizumab","description":"KEYTRUDA, as a single agent, is indicated for the treatment of patients with advanced endometrial carcinoma that is microsatellite instability-high (MSI⁠-⁠H) or mismatch repair deficient (dMMR), as determined by an FDA-approved test, who have disease progression following prior systemic therapy in any setting and are not candidates for curative surgery or radiation."}',
        '{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-endometrial-carcinoma/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠158","description":"KEYNOTE⁠-⁠158 enrolled and evaluated the efficacy of 90 patients with unresectable or metastatic MSI⁠-⁠H or dMMR endometrial carcinoma who received at least 1 dose of KEYTRUDA.","trialDesign":"KEYNOTE⁠-⁠158, a multicenter, non-randomized, open-label, multi-cohort trial, (n=90; cohorts D and K)."}',
        '{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/advanced-endometrial-carcinoma/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}'
    ]
}

//from /src/util/data/indications.js
const indicationId = 30;
const jobCode = jobCodes[32].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/advanced-endometrial-carcinoma/' },
                { text: 'Dosing Options', url: '/dosing/options/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
}

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;158' }, 
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;158' },
    { label: 'Appropriate Patients' }
]

const dorFootnotes = [
    {
        label: "+",
        text: "Denotes ongoing response."
    }
]

const patientProfileLorie = {
    image: () => (
        // image to be updated
        <StaticImage layout="fixed" src="../../assets/patient-lorrie.png" placeholder="TRACED_SVG" alt="Hypothetical Patient From KEYNOTE-158" width={100} height={100} />
    ),
    name: "Lorrie",
    diagnosis: "Stage IVB endometrial carcinoma",
    primaryCharacteristics: [
        {
            key: 'Treatment History',
            value: `Initial diagnosis: stage II endometrial carcinoma. Total laparoscopic hysterectomy, bilateral salpingo-oophorectomy, and pelvic lymph node dissection`,
            emphasize: false,
        },
        {
            key: 'Biomarker Status',
            value: 'dMMR',
            emphasize: false,
        },
        {
            key: 'Follow-up Treatment',
            value: 'Pelvic radiation and systemic chemotherapy',
            emphasize: false,
        },
        {
            key: '6-Month Follow-up',
            value: 'Follow-up exams every 6 months—no symptoms reported; follow-up evaluation negative',
            emphasize: false,
        },
        {
            key: '2-Year Follow-up',
            value: 'At 2-year visit, reports nausea, fatigue, and upper abdominal fullness. CT scan of abdomen and pelvis consistent with liver metastases. Whole-body PET/CT consistent with liver metastases (stage IVB)', 
            emphasize: false,
        },
    ],
    secondaryCharacteristics: [
        {
            key: 'Age',
            value: '70',
        },
        {
            key: 'Gender',
            value: 'Female',
        }
    ],
};

const pageReferences = [
    {
        label: "1.",
        text: "Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) Uterine Neoplasms v2.2024. National Comprehensive Cancer Network, Inc. 2024. Accessed March 12, 2024. To view the most recent and complete version of the guidelines, go online to NCCN.org.",
    },
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={ pageMeta } location={ location } >
            <div data-template-name="template-e">
                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                    <PageSection bgColor="gradient">
                        <PrimaryClaim theme="light">KEYTRUDA, as a single agent, for the treatment of appropriate patients with advanced endometrial carcinoma that is MSI&#8288;-&#8288;H/dMMR</PrimaryClaim>
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">

                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <PageSubSection title='KEYNOTE&#8288;-&#8288;158 data from cohorts D and K '>
                                <EndpointDisplay title="Objective Response Rate (ORR)"
                                    limitation="Efficacy was evaluated in 90 patients who received at least 1 dose of KEYTRUDA."
                                    definitions="CI = confidence interval."
                                    additionalInformation={[
                                        { text: "Median follow-up time of 16.0 months (range: 0.5–62.1)."},
                                    ]}
                                    cards={
                                        [
                                            {
                                                dataType: 'ORR',
                                                label: 'KEYTRUDA',
                                                rate: '46%',
                                                ci: '95% CI, 35–56',
                                                completeResponseRate: '12%',
                                                partialResponseRate: '33%',
                                            }
                                        ]
                                    }
                                />

                                <ComparisonTable title="Duration of Response (DOR)"
                                    footnotes={dorFootnotes}
                                    data={[
                                        [
                                            {
                                                legendTitle: 'KEYTRUDA (n=41) ',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Median DOR',
                                                dataPoint: 'Not reached',
                                                dataSubPoint: '(range: 2.9–55.7+ months)'

                                            },
                                        ],
                                        [
                                            {
                                                label: 'Patients with responses lasting ≥12 months',
                                                dataPoint: '68%',
                                            },
                                        ],
                                        [
                                            {
                                                label: 'Patients with responses lasting ≥24 months',
                                                dataPoint: '44%',
                                            },
                                        ],
                                    ]} />
                            </PageSubSection>

                            <PageSubSection title={anchorLinkModelData[1].label}>
                               <ENDO />
                            </PageSubSection>
                        </PageSection>

                        <PageSection title={anchorLinkModelData[2].label}>
                            <PageSubSection title="Hypothetical Patient Based on Those Studied in KEYNOTE&#8288;-&#8288;158<sup>1</sup>">
                                <PatientProfile 
                                    {...patientProfileLorie} 
                                    definitions="CT = computed tomography; PET = positron emission tomography."
                                />
                            </PageSubSection>
                        </PageSection>

                    </TemplateColumn>
                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
