import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview, StudyDesignArms, StudyCopyBlock } from '../index';

const ENDO = () => (
    
    <ComponentWrapper>
    <StudyDesignOverview 
        title="Study Overview"
        description="
            KEYNOTE&#8288;-&#8288;158, a multicenter, non-randomized, open-label, multi-cohort trial, (n=90; cohorts D and K).
        " 
        data={[
        [
            {
                title: 'Patient Population',
                description: `
                    <ul>
                        <li>Included patients with unresectable or metastatic MSI&#8288;-&#8288;H or dMMR endometrial carcinoma who received at least 1 dose of KEYTRUDA<sup>a</sup></li>
                        <li>Excluded patients with autoimmune disease or a medical condition that required immunosuppression</li>
                    </ul>
                `,
                type: 'box-gradient'
            }
        ]
    ]} />

    <StudyDesignArms title="Study Arm" arms={[
        {
            data: [
                [
                    {
                        title: 'Treatment',
                        description: `Patients received KEYTRUDA 200&nbsp;mg intravenously every 3 weeks.`,
                        type: 'box-green'
                    }
                ],
                [
                    {
                        type: 'arrow-green'
                    }
                ],
                [
                    {
                        title: 'Treatment Duration',
                        description: `
                        <p>Treatment with KEYTRUDA continued until unacceptable toxicity or documented disease progression. Patients treated with KEYTRUDA without disease progression could be treated for up to 24 months.</p>
                        `
                    }
                ]
            ]
        },
       
    ]} />

        <StudyCopyBlock 
            title="End Points"
            description={`
                <p>Major efficacy outcome measures<sup>b</sup>:</p>
                <ul>
                    <li>Objective response rate (ORR)</li>
                    <li>Duration of response (DOR)</li>
                </ul>
                <p>Assessment of tumor status was performed every 9 weeks for the first 12 months, and every 12 weeks thereafter.</p>

            `} 
        />


        <StudyCopyBlock 
            title="Patient Characteristics"
            description={`
                <p>KEYNOTE&#8288;-&#8288;158 enrolled and evaluated the efficacy of 90 patients with unresectable or metastatic MSI&#8288;-&#8288;H or dMMR endometrial carcinoma who received at least 1 dose of KEYTRUDA.</p>
                <p>Baseline Characteristics (n=90):</p>
                <ul>
                    <li>Median age: 64 years (range: 42–86)</li>
                    <li>Demographics:
                        <ul>
                            <li>White: 83%</li>
                            <li>Asian: 8%</li>
                            <li>Black: 3%</li>
                            <li>Hispanic or Latino: 12%</li>
                        </ul>
                    </li>
                    <li>ECOG PS:
                        <ul>
                            <li>Score of 0: 39%</li>
                            <li>Score of 1: 61%</li>
                        </ul>
                    </li>
                    <li>Disease state at study entry:
                        <ul>
                            <li>M0: 4%</li>
                            <li>M1: 96%</li>
                        </ul>
                    </li>
                    <li>Prior lines of therapy:
                        <ul>
                            <li>1: 51%</li>
                            <li>≥2: 48%</li>
                        </ul>
                    </li>
                    <li>9 patients received only adjuvant therapy before study</li>
                    <li>1 patient received only neoadjuvant and adjuvant therapy
                    before the study</li>
                </ul>
            `} 
            footnotes={[
                {
                    label:'a.',
                    text: 'MSI or MMR status was determined using polymerase chain reaction or immunohistochemistry, respectively.'
                },
                {
                    label:'b.',
                    text: `As assessed by BICR according to RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.`
                },
            ]}
            definitions={`
                RECIST = Response Evaluation Criteria in Solid Tumors; BICR =
                blinded independent central review; ECOG PS = Eastern Cooperative
                Oncology Group performance status; M0 = no metastasis; M1 = distant metastasis.
            `}
        />
</ComponentWrapper>
);

export default ENDO